import { InjectedConnector as Injected } from "@web3-react/injected-connector";
import {
  WalletConnectConnector,
  WalletConnectConnectorArguments,
} from "@web3-react/walletconnect-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
// import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { SUPPORTED_CHAIN_IDS, INFRA_PROJECT_ID } from "app-constants";
import {
  NetworkUrls,
  SupportedNetworks,
  WalletConnectorTypes,
  WalletOfficialSitesType,
} from "types";

export const BinanceWalletNetworkIds: NetworkUrls = {
  [SupportedNetworks.mainnet]: "eth",
  [SupportedNetworks.bsc]: "bsc-mainnet",
  [SupportedNetworks.bsctestnetwork]: "bsc-testnet",
};

// for metamask and gate wallet
const injected = new Injected({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
});

// coinbasewallet connector
// const CoinbaseWalletConnector = new WalletLinkConnector({
//   url: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
//   appName: '',
//   supportedChainIds: SUPPORTED_CHAIN_IDS,
// })

const trustWalletConnecter = new WalletConnectConnector({
  rpc: {
    1: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
    56: "https://bsc-dataseed.binance.org/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const Connectors = {
  [WalletConnectorTypes.METAMASK]: injected,
  [WalletConnectorTypes.TRUST_WALLET]: injected,
  [WalletConnectorTypes.GATE_WALLET]: injected,
};

export const WalletOfficialSites: WalletOfficialSitesType = {
  [WalletConnectorTypes.METAMASK]: "https://metamask.io",
  [WalletConnectorTypes.TRUST_WALLET]: "https://trustwallet.com",
  [WalletConnectorTypes.GATE_WALLET]: "https://www.gate.io/web3",
};
