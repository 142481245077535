import Web3 from "web3";
import Web3Utils, { toBN, AbiItem } from "web3-utils";
import { Eth } from "web3-eth";
import fromExponential from "from-exponential";

import {
  ETH_USDT,
  MAIN_ZERO_ADDRESS,
  LARGE_APPROVAL_AMOUNT,
  UNISWAP_ROUTER,
  ZERO_ADDRESS,
  UNISWAP_FACTORY,
  PANCAKE_FACTORY,
  WETH,
  WBNB,
} from "app-constants";
import uniswapAbi from "../Assets/Abi/uniswap.json";
import ERC20 from "../Assets/Abi/ERC20.json";
import BSwapPairAbi from "Assets/Abi/BSwapPairAbi.json";
import BSwapfactoryAbi from "Assets/Abi/BSwapFactoryAbi.json";
import { isEqAddr } from "./others";
import { simpleAmountInString } from "./math";

class Web3Helpers {
  web3;
  account;
  constructor(_web3: Web3, _account: string | null | undefined = "") {
    this.web3 = _web3;
    this.account = _account;
  }
  /**
   * @param {String} from address
   * @param {String} to address
   * @param {String} factoryContract factory address
   */
  getPair = async (from: string, to: string, factoryContract: string) => {
    try {
      const factory = new this.web3.eth.Contract(
        BSwapfactoryAbi as AbiItem[],
        factoryContract
      );
      const pair = await factory.methods.getPair(from, to).call();
      return pair;
    } catch (e) {
      // console.log(e);
      return ZERO_ADDRESS;
    }
  };

  getDecimal = async (tokenAddress: string) => {
    let decimal = 18;
    if (!tokenAddress) return decimal;

    try {
      const bal = new this.web3.eth.Contract(ERC20 as AbiItem[], tokenAddress);
      decimal = await bal.methods.decimals().call();
      return +decimal;
    } catch (e) {
      return 18; // return default decimals
    }
  };

  getReservesRatio = async (
    from: string,
    pairAddress: string,
    short = false
  ) => {
    try {
      const tokenPair = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        pairAddress
      );
      const token0 = await tokenPair.methods.token0().call();
      const reserve = await tokenPair.methods.getReserves().call();
      if (short)
        return String(token0).toLowerCase() === String(from).toLowerCase()
          ? reserve._reserve0
          : reserve._reserve1;
      return isEqAddr(token0, from)
        ? reserve._reserve0 / reserve._reserve1
        : reserve._reserve1 / reserve._reserve0;
    } catch (e) {
      return 0;
    }
  };

  getAmountOut = async ({
    fromToken,
    toToken,
    amount,
    router = UNISWAP_ROUTER,
    abi = uniswapAbi as AbiItem[],
    factoryContract = UNISWAP_FACTORY,
    isBsc = false,
    web3Instance = null,
  }: {
    fromToken: string;
    toToken: string;
    amount: number;
    router?: string;
    abi?: AbiItem[];
    factoryContract?: string;
    isBsc?: boolean;
    web3Instance?: Eth | null;
  }) => {
    const web3Eth = web3Instance ? web3Instance : this.web3.eth;
    // console.log('inside amount out function')
    const factories = new web3Eth.Contract(
      BSwapfactoryAbi as AbiItem[],
      factoryContract
    );
    // let path;
    const swaping = new web3Eth.Contract(abi as AbiItem[], router);
    try {
      const pairaddress = await factories.methods
        .getPair(fromToken, toToken)
        .call();
      const fromTokenContract = new web3Eth.Contract(
        ERC20 as AbiItem[],
        fromToken
      );
      const toTokenContract = new web3Eth.Contract(ERC20 as AbiItem[], toToken);

      const decimal0 = await fromTokenContract.methods.decimals().call();
      const decimal1 = await toTokenContract.methods.decimals().call();

      if (
        pairaddress === ZERO_ADDRESS &&
        [fromToken, toToken].includes(isBsc ? WBNB : WETH)
      ) {
        return ["0", "0", false, true];
      }
      if (pairaddress === ZERO_ADDRESS) {
        const path = [fromToken, isBsc ? WBNB : WETH, toToken];
        const multipied = String(
          fromExponential(amount * Math.pow(10, decimal0))
        );
        const amountIn = toBN(multipied.split(".")[0]);
        const res = await swaping.methods.getAmountsOut(amountIn, path).call();
        const amountOut = res[2] / Math.pow(10, decimal1);
        return [String(amountOut), res[2], true];
      } else {
        const path = [fromToken, toToken];

        const multipied = String(
          fromExponential(amount * Math.pow(10, decimal0))
        );
        const amountIn = await toBN(multipied.split(".")[0]);
        const res = await swaping.methods.getAmountsOut(amountIn, path).call();
        amount = parseFloat(res[1]) / 10 ** decimal1;
        return [String(amount), res[1]];
      }
    } catch (error) {
      console.error(error);
      return ["0", "0"];
    }
  };

  getTokenBalance = async (address = ZERO_ADDRESS, account = this.account) => {
    let bal = "";
    try {
      if (!account) {
        throw new Error("Please initialize Web3Helper with account");
      }
      if (isEqAddr(address, ZERO_ADDRESS)) {
        bal = await this.web3.eth.getBalance(account);
      } else {
        const contract = new this.web3.eth.Contract(
          ERC20 as AbiItem[],
          address
        );
        bal = await contract.methods.balanceOf(account).call();
      }
    } catch (err) {
      console.log(err);
    }
    return bal;
  };

  compareBalance = async (
    address: string,
    amount: number = LARGE_APPROVAL_AMOUNT
  ) => {
    const decimal = await this.getDecimal(address);
    try {
      const balance = await this.getTokenBalance(address);
      const available = +balance / Math.pow(10, decimal);
      if (available <= amount) return Web3Utils.toBN(balance);
      return Web3Utils.toBN(amount + "0".repeat(decimal));
    } catch (e) {
      return Web3Utils.toBN(amount + "0".repeat(decimal));
    }
  };

  getImpact = async (
    from: string,
    to: string,
    fromAmt: number | string,
    toAmt: number | string,
    factory: string
  ) => {
    if (!fromAmt || !toAmt) return 0;
    let reserveRatio;
    fromAmt = Web3Utils.toWei(String(fromAmt));
    toAmt = Web3Utils.toWei(String(toAmt));
    const swapFee = factory === PANCAKE_FACTORY ? 0.25 : 0.3;
    const pairAddress = await this.getPair(from, to, factory);
    // const r = await getReservesRatio(from, pairAddress);
    if (pairAddress !== ZERO_ADDRESS)
      reserveRatio = await this.getReservesRatio(from, pairAddress);
    else {
      const mediator = factory === PANCAKE_FACTORY ? WBNB : WETH;
      const pairAB = await this.getPair(from, mediator, factory);
      const pairBC = await this.getPair(mediator, to, factory);
      const ratioAB = await this.getReservesRatio(from, pairAB);
      const ratioBC = await this.getReservesRatio(mediator, pairBC);
      reserveRatio = ratioAB * ratioBC;
    }
    const amtRatio = +fromAmt / +toAmt;
    return (1 - reserveRatio / amtRatio) * 100 - swapFee;
  };

  ethUsdtValue = async (amt: number) => {
    try {
      const decimals = await this.getDecimal(WETH);
      const bigAmt = await toBN(fromExponential(amt * Math.pow(10, decimals)));
      const swaping = new this.web3.eth.Contract(
        uniswapAbi as AbiItem[],
        UNISWAP_ROUTER
      );
      const path = [WETH, ETH_USDT];
      const res = await swaping.methods.getAmountsOut(bigAmt, path).call();
      return res[1];
    } catch (e) {
      return 1;
    }
  };

  findAllowedAmount = async (
    tokenAddress: string,
    amount: number,
    spender: string,
    callback?: (allowance: number | boolean) => void
  ) => {
    try {
      if (isEqAddr(tokenAddress, ZERO_ADDRESS)) return false;
      const decimal = await this.getDecimal(tokenAddress);
      const contract = new this.web3.eth.Contract(
        ERC20 as AbiItem[],
        tokenAddress
      );
      let allowed = await contract.methods
        .allowance(this.account, spender)
        .call();
      allowed = +allowed / Math.pow(10, decimal);
      typeof callback === "function" && callback(allowed < amount);
      return allowed >= amount;
    } catch (error) {
      typeof callback === "function" && callback(true);
      return true;
    }
  };

  approveTokenTo = async (
    token: string,
    tokenDecimals: string | number,
    amount: number | string,
    spender: string,
    account: string | null = this.account
  ) => {
    const contract = new this.web3.eth.Contract(ERC20 as AbiItem[], token);
    const bigAmount = simpleAmountInString(+amount, tokenDecimals); //  Web3Utils.toBN(fromExponential(+amount * Math.pow(10, +tokenDecimals)))
    const approveMethod = contract.methods.approve(spender, bigAmount);
    // to check params
    await approveMethod.estimateGas({ from: account });
    const res = await approveMethod.send({ from: account });
    return res;
  };

  // feature 1: dont approve if already approved the required amount
  // feature 2: dont approve main tokens
  smartApproveToken = async (
    _tokenAddress: string,
    _tokenDecimals: string | number,
    _amount: string | number,
    _spender: string,
    _account: string,
    mainToken: string
  ): Promise<boolean> => {
    if (isEqAddr(mainToken, _tokenAddress)) return true;
    if (isEqAddr(ZERO_ADDRESS, _tokenAddress)) return true;

    const isAllowed = await this.findAllowedAmount(
      _tokenAddress,
      +_amount,
      _spender
    );
    if (isAllowed) return true;
    await this.approveTokenTo(
      _tokenAddress,
      _tokenDecimals,
      _amount,
      _spender,
      _account
    );
    return true;
  };

  // getPoolShare = async (from: string, to: string, factoryAddr: string) => {
  //   try {
  //     const pair = await this.getPair(from, to, factoryAddr)
  //     if (![ZERO_ADDRESS, MAIN_ZERO_ADDRESS].includes(pair)) {
  //       const fromContract = new this.web3.eth.Contract(ERC20 as AbiItem[], from)
  //       const toContract = new this.web3.eth.Contract(ERC20 as AbiItem[], to)
  //       const fromShare = (await fromContract.methods.balanceOf(pair).call()) / Math.pow(10, await this.getDecimal(from)) || 1
  //       const toShare = (await toContract.methods.balanceOf(pair).call()) / Math.pow(10, await this.getDecimal(to)) || 1
  //       // console.log({ fromShare, toShare });
  //       return { fromShare, toShare }
  //     } else {
  //       return { fromShare: 1, toShare: 1 }
  //     }
  //   } catch (e) {
  //     return { fromShare: 1, toShare: 1 }
  //   }
  // }

  getCircuitBreakers = async (from: string, to: string, factory: string) => {
    try {
      if (!(from && to)) throw new Error("Missing arguments");
      const pair = await this.getPair(from, to, factory);
      if ([MAIN_ZERO_ADDRESS, ZERO_ADDRESS].includes(pair))
        throw new Error("No pool exist");
      const pairContract = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        pair
      );
      const res = [];
      // const switcher = { '3600': 'hour', '86400': 'day' };
      const token0 = await pairContract.methods.token0().call();
      const values = await Promise.all(
        [0, 1, 2, 3, 4].map(async (num) => {
          return await pairContract.methods.vars(num).call();
        })
      );
      res[2] =
        +values[0] === 3600
          ? "hour"
          : values[0] / 3600 === 24
          ? "day"
          : `${values[0] / 3600} hours`;
      res[0] =
        (token0.toLowerCase() === from.toLowerCase() ? values[1] : values[2]) /
        100;
      res[1] =
        (token0.toLowerCase() === from.toLowerCase() ? values[3] : values[4]) /
        100;

      return res;
    } catch (e) {
      return [0, 0, "day"];
    }
  };

  getFluctuationFees = async (
    from: string,
    to: string,
    amt: number,
    reverse = false,
    factory: string
  ): Promise<number> => {
    try {
      if (+amt === 0) {
        return amt;
      }
      if (!(from && to && amt)) throw new Error("Missing arguments");
      const pair = await this.getPair(from, to, factory);
      const amtDecimals = await this.getDecimal(from);
      const bigAmt = simpleAmountInString(amt, amtDecimals);
      if ([MAIN_ZERO_ADDRESS, ZERO_ADDRESS].includes(pair))
        throw new Error("No pool exist");
      const pairContract = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        pair
      );
      if (!reverse) {
        const { fee } = await pairContract.methods
          .getAmountInAndFee(bigAmt, from, to)
          .call();
        return fee / 100;
      } else {
        const { fee } = await pairContract.methods
          .getAmountOutAndFee(bigAmt, from, to)
          .call();
        return fee / 100;
      }
    } catch (e) {
      // console.log(e);
      return 0;
    }
  };

  // getDmcFees = async () => {
  //   const pool = '0x962C9862cb1837C1B2073A3011c7F0D58Fd229a3'
  //   const baselinePrice = 0.000015267175572518
  //   try {
  //     const ratio = await this.getReservesRatio(WBNB, pool)
  //     return (ratio / baselinePrice - 1) * 100
  //   } catch (e) {
  //     // console.log(e);
  //     return 0
  //   }
  // }

  getTokenInfo = async (_token: string, account: string) => {
    const tokenContract = new this.web3.eth.Contract(
      ERC20 as AbiItem[],
      _token
    );
    const _symbol: string = await tokenContract.methods
      .symbol()
      .call()
      .catch((e: any) => e);
    const _name: string = await tokenContract.methods
      .name()
      .call()
      .catch((e: any) => e);
    const _decimals: string = await tokenContract.methods
      .decimals()
      .call((e: any) => e);
    const _balance: string = await this.getTokenBalance(_token, account).catch(
      (e: any) => e
    );

    return {
      name: _name,
      symbol: _symbol,
      decimals: _decimals,
      balance: _balance,
    };
  };
}

export default Web3Helpers;
