import React, { useState } from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'

import useWallet from 'hooks/useWallet'
import TokenIcon from 'Component/TokenIcon'
import { NETWORKS, VESTING_PERIOD } from 'app-constants'
import tooltip from 'app-constants/tooltip-texts'
import AppTooltip from 'Component/AppTooltip'
import { AppButton, BackButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import { BondSContentTable, BondSelectPair, BondTabTitle, EditButton, SContentSubTable } from 'Component/core/elements/IBO-SharedComponents'
import TransactionModals from 'Component/popup/transactions/transaction-popups'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { prevStep, setStep, createLpInsureOffer } from 'redux/reducers/lp-insure/actions'
import { LPInsureSelector } from 'redux/selectors'
import { LPInsureTabs } from 'types'
import { useAppChain, useFailedInfoModal, useProcessingModal, useTransactionModal, useWeb3 } from 'hooks'
import Web3Helpers from 'helper/Web3Helpers'
import { LP_INSURE_PROXY_FACTORY } from 'app-constants/lp-insure'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'

function LPInsureDeployBondPool() {
  const web3 = useWeb3()
  const [openLoadingModal, closeLoadingModal] = useProcessingModal()
  const [openFTModal] = useFailedInfoModal()

  const { account } = useWallet()
  const { appChainId } = useAppChain()
  const dispatch = useAppDispatch()
  const lpInsure = useAppSelector(LPInsureSelector)
  const tokenInfo = lpInsure.tokenInfo

  const [isSupplyApproved, setIsSupplyApproved] = useState(false)
  const { open: openTRModal, close: closeTRModal, hash: TRMHash, isOpen: isTRMOpen } = useTransactionModal()

  const createPool = async () => {
    try {
      openLoadingModal('Creating LP insure offer...')
      const result = await dispatch(createLpInsureOffer())
      openTRModal(result.transactionHash)
    } catch (error: unknown) {
      console.log(error)
      // @ts-ignore
      const errorMessage = error.message || error.toString()
      openFTModal(errorMessage)
    } finally {
      closeLoadingModal()
    }
  }

  const approveSupply = async(): Promise<void> => {
    try {
      openLoadingModal('Approving Supply....')
      const spender = LP_INSURE_PROXY_FACTORY[appChainId]
      const mainToken = NETWORKS[appChainId].W_TOKEN_ADDRESS
      const web3Helper = new Web3Helpers(web3, account);
      await web3Helper.smartApproveToken(lpInsure.token, tokenInfo.decimals, lpInsure.amountOfSupply, spender, account as string, mainToken)
      setIsSupplyApproved(true)
    } catch (error: unknown) {
      console.log(error)
      // @ts-ignore
      const errorMessage = error.message || error.toString()
      openFTModal(errorMessage)
    } finally {
      closeLoadingModal()
    }
  }

  const handleOnClickBack = () => {
    dispatch(prevStep())
  }
  const goToProjectInfo = () => {
    dispatch(setStep(0))
  }
  const goToFundraisingTerms = () => {
    dispatch(setStep(LPInsureTabs.FundraisingTerms))
  }
  useAppDebounceEffect(() => {
    (async () => {
      try {
        setIsSupplyApproved(false)
        const spender = LP_INSURE_PROXY_FACTORY[appChainId]
        const web3Helper = new Web3Helpers(web3, account);
        const isApproved = await web3Helper.findAllowedAmount(lpInsure.tokenInfo.address, +lpInsure.amountOfSupply, spender)
        setIsSupplyApproved(isApproved === true)
      } catch (error) {
        setIsSupplyApproved(false)
      }
    })();
  }, 300, [appChainId, web3, account])

  return (
    <React.Fragment>
      <div className='py-20'>
        <BackButton onClick={handleOnClickBack} />
      </div>
      <div>
        <BondTabTitle>Project Information</BondTabTitle>
        <BondSContentTable className='highlighted-border-bottom'>
          <tbody>
            <tr>
              <td className='vertical-align-middle'>
                <span>Place your token contract</span>
              </td>
              <td className='vertical-align-middle'>
                <div className='d-flex w-full'>
                  <span>{lpInsure.token.slice(0, -15)}...</span>
                  <span className='ml-20 text-uppercase'>[token]</span>
                </div>
              </td>
            </tr>
            <tr>
              <td className='vertical-align-middle'>
                <span>Select a DEX</span>
              </td>
              <td className='d-flex align-items-center justify-content-between position-relative w-full'>
                {lpInsure.dex.title}
              </td>
              <td className='vertical-align-middle'>
                <span>Select a liquidity pool</span>
              </td>
              <td className='d-flex align-items-center justify-content-between position-relative w-full'>
                <BondSelectPair>
                  <TokenIcon className='md' byAddress={lpInsure.tokenInfo.address} />
                  <span>{lpInsure.tokenInfo.symbol}</span>
                  <span>&nbsp;{'<>'}&nbsp;</span>
                  <TokenIcon className='md' byAddress={lpInsure.pairToken.address} />
                  <span>{lpInsure.pairToken.symbol}</span>
                </BondSelectPair>
                <div>
                  <EditButton onClick={goToProjectInfo} />
                </div>
              </td>
            </tr>
          </tbody>
        </BondSContentTable>
      </div>
      <div>
        <BondTabTitle>Bond pool terms</BondTabTitle>
        <BondSContentTable className='mb-0'>
          <tbody>
            <tr>
              <td>
                <span>Vesting period</span>
              </td>
              <td>
                <span>{lpInsure.vestingPeriod.type === VESTING_PERIOD.CLIFF ? 'Cliff' : 'Graded'}</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </td>
              {lpInsure.vestingPeriod.type === VESTING_PERIOD.CLIFF ? (
                <>
                  <td>
                    <span>After</span>
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </td>
                  <td className='text-white'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <span>
                        {lpInsure.vestingPeriod.afterValue}&nbsp;{lpInsure.vestingPeriod.afterOption.label}
                      </span>
                      <div className='w-full d-flex justify-content-end'>
                        <EditButton className='mt-5' onClick={goToFundraisingTerms} />
                      </div>
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td>
                    <span>Portion</span>
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </td>
                  <td className='text-white'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <span>{lpInsure.vestingPeriod.portionValue}%</span>
                      <div className='w-full d-flex justify-content-end'>
                        <EditButton className='mt-5' onClick={goToFundraisingTerms} />
                      </div>
                    </div>
                  </td>
                </>
              )}

            </tr>
            <tr>
              <td>
                <span>Prepayment penalty</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </td>
              <td>
                <span>{lpInsure.prepaymentPenalty ? 'Yes' : 'No'}</span>
              </td>
              <td hidden={!lpInsure.prepaymentPenalty}>
                <span>Dynamic penalty</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </td>
              <td hidden={!lpInsure.prepaymentPenalty}>
                <span>{lpInsure.dynamicPenalty}%</span>
              </td>
            </tr>
          </tbody>
        </BondSContentTable>
      </div>
      <Divider className='vertical mt-15 mb-10 highlighted-border-bottom' />
      <div>
        <BondTabTitle>Fundraising terms</BondTabTitle>
        <BondSContentTable className='mb-0'>
          <tbody>
            <tr>
              <td>
                <span>Choose amount of supply</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Pool_Supply_Amount')} />
              </td>
              <td>
                <span>
                  {lpInsure.amountOfSupply}
                </span>
              </td>
              <td></td>
              <td>
                <div className='text-end'>
                  {tokenInfo.symbol}
                </div>
              </td>
            </tr>
          </tbody>
        </BondSContentTable>
        <Divider className='vertical mt-15 mb-10 highlighted-border-bottom' />
        <BondSContentTable className='mb-10'>
          <tbody>
            <tr>
              <td>
                <span>Investment limit per wallet</span>
              </td>
              <td></td>
              <td colSpan={2} className='w-full'>
                <SContentSubTable className='w-full'>
                  <tbody>
                    <tr>
                      <td className='text-muted'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <span>{lpInsure.investmentLimit.isSet ? 'Yes' : 'No'}</span>
                          <EditButton onClick={goToFundraisingTerms} />
                        </div>
                      </td>
                    </tr>
                    {lpInsure.investmentLimit.isSet && (
                      <>
                        <tr>
                          <td>
                            <span>Minimum</span>
                          </td>
                          <td>${lpInsure.investmentLimit.minimum}</td>
                        </tr>
                        <tr>
                          <td>
                            <span>Maximum</span>
                          </td>
                          <td>${lpInsure.investmentLimit.maximum}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </SContentSubTable>
              </td>
            </tr>
          </tbody>
        </BondSContentTable>
      </div>
      <Divider className='vertical mb-10 highlighted-border-bottom' />
      <div>
        <BondTabTitle>Fees</BondTabTitle>
        <BondSContentTable>
          <tbody>
            <tr>
              <td className='pb-10'>
                {/* 2.5% is default value */}
                <span>2.5% will be charge from users</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('TODO')} />
              </td>
            </tr>
            <tr>
              <td className='pb-10'>
                {/* 2.5% is default value */}
                <span>2.5% will be charge from project</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('TODO')} />
              </td>
            </tr>
          </tbody>
        </BondSContentTable>
      </div>
      <Divider className='vertical mb-10 highlighted-border-bottom' />
      <div className='d-flex justify-content-center py-50'>
        {
          isSupplyApproved
            ? <AppButton className='text-uppercase outline-primary w-max-500 fs-xl' onClick={createPool}>
              Deploy Bond Pool
            </AppButton>
            : <AppButton className='text-uppercase outline-primary w-max-500 fs-xl' onClick={approveSupply}>
              Approve Supply
            </AppButton>
        }
      </div>
      <AppTooltip />
      <TransactionModals.Success isOpen={isTRMOpen} close={closeTRModal} hash={TRMHash}  />
    </React.Fragment>
  )
}

export default LPInsureDeployBondPool
